import {useContext, useReducer, useMemo, createContext} from 'react'
import {Ability} from '@casl/ability'

const init = {
  privilegeGroup: null,
  privilegeListGroup: [],
}
export const AbilityGroupContext = createContext(init)

const listAbility = (state, {type, payload}) => {
  switch (type) {
    case 'PRIVILEGE_GROUP': {
      return {
        ...state,
        privilegeGroup: payload ? new Ability(payload) : null,
      }
    }
    case 'PRIVILEGE_LIST_GROUP': {
      return {
        ...state,
        privilegeListGroup: [
          ...state.privilegeListGroup,
          {group: payload.group, ability: new Ability(payload.ability)},
        ],
      }
    }

    default:
      return state
  }
}

export const AbilityGroupProvider = ({children, initialValue = {}}) => {
  const [state, dispatch] = useReducer(listAbility, init)

  const setPrivilegeGroup = data => {
    dispatch({type: 'PRIVILEGE_GROUP', payload: data})
  }

  const setPrivilegeListGroup = data => {
    dispatch({type: 'PRIVILEGE_LIST_GROUP', payload: data})
  }

  const store = useMemo(() => {
    return {
      state: {...state, ...initialValue},
      setPrivilegeGroup,
      setPrivilegeListGroup,
    }
  }, [initialValue, state])

  return (
    <AbilityGroupContext.Provider value={store}>
      {children}
    </AbilityGroupContext.Provider>
  )
}

export const useAbilityGroupCtx = () => {
  const context = useContext(AbilityGroupContext)
  return context
}
