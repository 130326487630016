// ** Icons Import

const Footer = () => {
  return (
    <p className="clearfix mb-0">
      {/*<span className="float-md-start d-block d-md-inline-block mt-25">*/}
      {/*  COPYRIGHT © {new Date().getFullYear()}{' '}*/}
      {/*  <span className="text-primary">B-Alpha</span>*/}
      {/*  <span className="d-none d-sm-inline-block">, All rights Reserved</span>*/}
      {/*</span>*/}
      {/* <span className="float-md-end d-none d-md-block">
        Hand-crafted & Made with
        <Heart size={14} />
      </span> */}
    </p>
  )
}

export default Footer
