const INITIALSTATE = {
  post_id: null,
  post_id_share: null,
  isToggleModal: false,
  isToggleSubModal: false,
  stepper: 0,
  tags: [],
  mentions: [],
  audience_type: {},
  business_level: null,
  datetime_end_pin: null,
  datetime_start_pin: new Date(),
  content: '',
  embed_link: null,
  images: [],
  images_edit: [],
  videos: null,
  group_share: null,

  target_share_info: null,
  post_group_info: null,

  idProfileViewer: null,
  groupIdForTags: null,
}

export default INITIALSTATE
