// ** Redux Imports
import {createSlice, current} from '@reduxjs/toolkit'

export const postSlide = createSlice({
  name: 'post',
  initialState: {
    // ** newsfeed
    target_id_delete_cmt: null, // id của bài viết khi xóa cmt
    // ** group
    idGroupDetail: '',
    // ** newsfeed profile
    idProfileViewer: null,
  },
  reducers: {
    setIdGroupDetail: (state, {payload}) => {
      state.idGroupDetail = payload
    },
    handleDeleteCmt: (state, {payload}) => {
      state.target_id_delete_cmt = payload
    },

    setIdProfileViewer: (state, {payload}) => {
      state.idProfileViewer = payload
    },
  },
})

export const {setIdGroupDetail, handleDeleteCmt, setIdProfileViewer} =
  postSlide.actions

export default postSlide.reducer
