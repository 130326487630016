import { useMutation, useQueryClient } from 'react-query'
import toast from 'react-hot-toast'
import SocialNetworkApi from './api'

const useReadNotification = (data, key) => {
  const queryClient = useQueryClient()
  return useMutation(SocialNetworkApi.read_notification, {
    onSuccess: res => {
      queryClient.invalidateQueries(key)
      toast.success(res.message ? res.message : 'Đánh dấu đã đọc thành công')
    },
    onError: error => {
      toast.error(error.response.data?.msg || 'Đánh dấu đã đọc thất bại')
    },
  })
}

const SocialNetworkMutation = {
  useReadNotification
}

export default SocialNetworkMutation