import MODAL_TYPE from './type'

const modalReducer = (state, {type, payload, metadata}) => {
  switch (type) {
    case MODAL_TYPE.TOGGLE_MODAL: {
      return {
        ...state,
        isToggleModal: payload,
      }
    }
    case MODAL_TYPE.SET_POSITION_MODAL: {
      return {
        ...state,
        post_description: state.content,
        stepper: payload,
      }
    }
    case MODAL_TYPE.SET_ID_CATEGORY: {
      return {
        ...state,
        id_category: payload,
      }
    }
    case MODAL_TYPE.SET_ID_POST: {
      return {
        ...state,
        post_id: payload,
      }
    }
    case MODAL_TYPE.SET_STATE_PRIVACY: {
      return {
        ...state,
        audience_type: payload,
      }
    }
    case MODAL_TYPE.SET_ADD_PHOTO: {
      return {
        ...state,
        images: [...state.images, ...payload],
      }
    }
    case MODAL_TYPE.SET_POST_VALUE: {
      return {
        ...state,
        content: payload,
      }
    }
    case MODAL_TYPE.SET_TAG_FRIEND: {
      return {
        ...state,
        mentions: payload,
      }
    }

    case MODAL_TYPE.SET_WITH_FRIEND: {
      return {
        ...state,
        tags: payload,
      }
    }

    case MODAL_TYPE.TOGGLE_SUB_MODAL_ACTION: {
      return {
        ...state,
        isToggleSubModal: payload, datetime_end_pin: payload.datetime_end_pin || null,
        datetime_start_pin: payload.datetime_start_pin || null,
        content: payload.post_text || '',
        embed_link: payload.post_embedding?.detail || '',
        images_edit: payload.post_picture || [],
      }
    }
    case MODAL_TYPE.SET_ID_POST_SHARE: {
      return {
        ...state,
        post_id_share: payload,
      }
    }

    case MODAL_TYPE.GET_DETAIL_POST: {
      const renderPrivacy = privacy => {
        if (payload.post_group_info && payload.type === 'newsfeed-group') {
          return {
            key: 'your_group_newfeed',
            value: privacy.id,
          }
        } else {
          switch (privacy.name) {
            case 'Công khai':
              return {
                key: 'public',
                value: privacy.id,
              }
            case 'Cấp bậc kinh doanh':
              return {
                key: 'business_level',
                value: privacy.id,
              }
            case 'Riêng tư':
              return {
                key: 'private',
                value: privacy.id,
              }
            case 'Tin nổi bật':
              return {
                key: 'pin',
                value: privacy.id,
              }

            default:
              break
          }
        }
      }

      return {
        ...state,
        post_id: payload.post_id || null,
        // post_id_share: payload.target_share_info,
        tags:
          payload.user_tags?.map(it => ({
            ...it,
            uid: it.uid,
            name: it.name,
          })) || [],
        mentions: payload.user_mentions.map(it => it.user_id) || [],
        audience_type: renderPrivacy(payload.post_privacy) || {},
        business_level:
          {
            title: payload.post_business_level?.name,
            value: payload.post_business_level?.id,
            key: payload.post_business_level?.name,
          } || null,
        datetime_end_pin: payload.datetime_end_pin || null,
        datetime_start_pin: payload.datetime_start_pin || new Date(),
        content: payload.post_text || '',
        embed_link: payload.post_embedding?.detail || '',
        images_edit: payload.post_picture || [],
        videos: payload.post_videos || [],
        target_share_info: payload.target_share_info,
        post_group_info: payload.post_group_info,
      }
    }
    case MODAL_TYPE.SET_TARGET_SHARE_POST: {
      return {
        ...state,
        target_share_info: payload,
      }
    }
    case MODAL_TYPE.DELETE_IMAGE_EDIT: {
      return {
        ...state,
        images_edit: state.images_edit.filter(img => img !== payload),
      }
    }

    case MODAL_TYPE.DELETE_IMAGE: {
      return {
        ...state,
        images: state.images.filter(img => img.name !== payload.name),
      }
    }

    case MODAL_TYPE.DELETE_VIDEO: {
      return {
        ...state,
        videos: null,
      }
    }

    case MODAL_TYPE.BUSINESS_LEVEL: {
      return {
        ...state,
        business_level: payload,
      }
    }

    case MODAL_TYPE.RESET_FORM_CREATE_POST: {
      return {
        ...state,
        post_id: null,
        post_id_share: null,
        isToggleModal: false,
        isToggleSubModal: false,
        stepper: 0,
        tags: [],
        mentions: [],
        audience_type: {},
        business_level: null,
        datetime_start_pin: new Date(),
        datetime_end_pin: null,
        content: '',
        embed_link: null,
        images: [],
        images_edit: [],
        videos: null,
        group_share: null,

        target_share_info: null,
        post_group_info: null,
      }
    }

    case MODAL_TYPE.UPLOAD_VIDEO_POST: {
      return {
        ...state,
        videos: payload,
      }
    }
    case MODAL_TYPE.SET_START_TIME: {
      return {
        ...state,
        datetime_start_pin: payload,
      }
    }
    case MODAL_TYPE.SET_TIME_EXPECTED: {
      return {
        ...state,
        datetime_end_pin: payload,
      }
    }

    case MODAL_TYPE.SET_GROUP_SHARE: {
      return {
        ...state,
        group_share: payload,
      }
    }
    case MODAL_TYPE.SET_ID_PROFILE_VIEWER: {
      return {
        ...state,
        idProfileViewer: payload,
      }
    }

    case MODAL_TYPE.SET_GROUP_ID_FOR_TAGS: {
      return {
        ...state,
        groupIdForTags: payload,
      }
    }
    default:
      return state
  }
}

export default modalReducer
