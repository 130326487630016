import {Ability} from '@casl/ability'
import {initialAbility} from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))
const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)

// export const PostProvider = ({children, initialValue = {}}) => {
//     const [state, dispatch] = useReducer(listPosts, initialPosts)

//     const setLoadingComment = data => {
//       dispatch({type: POSTS_TYPE.SET_LOADING_COMMENT, payload: {data}})
//     }

//     const store = useMemo(() => {
//       return {
//         state: {...state, ...initialValue},
//         setLoadingComment,
//       }
//     }, [initialValue, state])

//   }
