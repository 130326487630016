import toast from 'react-hot-toast'
import {useMutation, useQueryClient} from 'react-query'
import KEYS from './key'
import ProfileApis from './api'

const useKycCloseWelcome = () => {
  return useMutation(ProfileApis.kyc_close_welcome)
}

const ProfileMutation = {
  useKycCloseWelcome,
}
export default ProfileMutation
