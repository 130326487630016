const KEYS = {
  LIST_REQUEST_ACCESS: 'LIST_REQUEST_ACCESS',
  GET_LIST_POSTS: 'GET_LIST_POSTS',
  GET_LIST_ALL_POST: 'GET_LIST_ALL_POST',
  GET_LIST_ALL_POST_TAB_GROUP: 'GET_LIST_ALL_POST_TAB_GROUP',
  GET_LIST_PRIVACIES: 'GET_LIST_PRIVACIES',
  GET_LIST_COMMENTS_OF_POST: 'GET_LIST_COMMENTS_OF_POST',
  GET_LIST_REPLIES_COMMENT: 'GET_LIST_REPLIES_COMMENT',
  GET_LIST_HASHTAGS: 'GET_LIST_HASHTAGS',
  GET_LIST_PRIVACY_CREATE_POST: 'GET_LIST_PRIVACY_CREATE_POST',
  GET_LIST_POST_PIN: 'GET_LIST_POST_PIN',
  GET_LIST_HOT_AREA: 'GET_LIST_HOT_AREA',
  GET_POST_DETAIL_BY_POST_ID: 'GET_POST_DETAIL_BY_POST_ID',
  GET_LIST_TYPE_REACTION_POST: 'GET_LIST_TYPE_REACTION_POST',
  GET_LIST_USER_REACT_POST: 'GET_LIST_USER_REACT_POST',
  // GET_LIST_REACTION_OF_POST: 'GET_LIST_REACTION_OF_POST',
  // GET_LIST_REACTION_OF_COMMENT: 'GET_LIST_REACTION_OF_COMMENT',
  GET_EMBED: 'GET_EMBED',
  GET_EMBED_POST: 'GET_EMBED_POST',
  GET_LIST_POST: 'GET_LIST_POST',

  GET_LIST_ALL_POST_PROFILE: 'GET_LIST_ALL_POST_PROFILE',
  LIST_GROUP_FOLLOWING: 'LIST_GROUP_FOLLOWING',

  GET_LIST_PROFILE_SUGGEST: 'GET_LIST_PROFILE_SUGGEST',
  GET_LIST_GROUP_SUGGEST: 'GET_LIST_GROUP_SUGGEST',
}

export default KEYS
