const MODAL_TYPE = {
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  SET_POSITION_MODAL: 'SET_POSITION_MODAL',
  SET_ID_CATEGORY: 'SET_ID_CATEGORY',
  SET_ID_POST: 'SET_ID_POST',
  SET_ID_POST_SHARE: 'SET_ID_POST_SHARE',
  SET_STATE_PRIVACY: 'SET_STATE_PRIVACY',
  SET_ADD_PHOTO: 'SET_ADD_PHOTO',
  SET_POST_VALUE: 'SET_POST_VALUE',
  SET_TAG_FRIEND: 'SET_TAG_FRIEND',
  SET_WITH_FRIEND: 'SET_WITH_FRIEND',
  SET_HASH_TAGS: 'SET_HASH_TAGS',
  SET_BOOK_MARK: 'SET_BOOK_MARK',
  TOGGLE_SUB_MODAL_ACTION: 'TOGGLE_SUB_MODAL_ACTION',
  GET_DETAIL_POST: 'GET_DETAIL_POST',
  DELETE_IMAGE: 'DELETE_IMAGE',
  DELETE_IMAGE_EDIT: 'DELETE_IMAGE_EDIT',
  RESET_FORM_CREATE_POST: 'RESET_FORM_CREATE_POST',
  UPLOAD_VIDEO_POST: 'UPLOAD_VIDEO_POST',
  DELETE_VIDEO: 'DELETE_VIDEO',

  BUSINESS_LEVEL: 'BUSINESS_LEVEL',
  SET_START_TIME: 'SET_START_TIME',
  SET_TIME_EXPECTED: 'SET_TIME_EXPECTED',
  SET_GROUP_SHARE: 'SET_GROUP_SHARE',
  SET_TARGET_SHARE_POST: 'SET_TARGET_SHARE_POST',

  SET_ID_PROFILE_VIEWER: 'SET_ID_PROFILE_VIEWER',
  SET_GROUP_ID_FOR_TAGS: 'SET_GROUP_ID_FOR_TAGS',
}

export default MODAL_TYPE
