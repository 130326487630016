// ** React Imports
import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'

// ** Custom Components
import Avatar from '@core/components/avatar'

// ** Utils
import {getUserData, isUserLoggedIn} from 'utility/Utils'

// ** Store & Actions
import {useDispatch} from 'react-redux'
import {handleLogout} from 'redux/authentication'

// ** Third Party Components
import {
  User,
  // Mail,
  // CheckSquare,
  // MessageSquare,
  // Settings,
  // CreditCard,
  // HelpCircle,
  Power,
} from 'react-feather'

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap'

import {ProfileQuery} from 'hook/profile'

const logo =
  'https://res.cloudinary.com/cloudhspace/image/upload/v1652172821/dev.hspace.biz/user-profile/trend-avatar-1_xxdjif.jpg'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()

  let role = ''
  let user = null
  if (isUserLoggedIn() !== null) {
    user = getUserData()
  }
  if (user && user.roles && user.roles.length > 0) {
    role = user.roles[0]?.rol_name
  }

  if (!user) return null

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={e => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">
            {user.full_name}{' '}
          </span>
          {role && <span className="user-status">{role}</span>}
        </div>
        <Avatar
          img={user.profile_image || logo}
          imgHeight="40"
          imgWidth="40"
          status="online"
          onError={e => {
            e.target.onerror = null
            e.target.src = logo
          }}
        />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to={`/profile/${user?.uid}/feed`}>
          <User size={14} className="me-75" />
          <span className="align-middle">Trang cá nhân </span>
        </DropdownItem>
        {/* <DropdownItem tag={Link} to="/apps/email">
          <Mail size={14} className="me-75" />
          <span className="align-middle">Inbox</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/apps/todo">
          <CheckSquare size={14} className="me-75" />
          <span className="align-middle">Tasks</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/apps/chat">
          <MessageSquare size={14} className="me-75" />
          <span className="align-middle">Chats</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to="/pages/account-settings">
          <Settings size={14} className="me-75" />
          <span className="align-middle">Settings</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/pages/pricing">
          <CreditCard size={14} className="me-75" />
          <span className="align-middle">Pricing</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/pages/faq">
          <HelpCircle size={14} className="me-75" />
          <span className="align-middle">FAQ</span>
        </DropdownItem> */}
        <DropdownItem tag="div" onClick={() => dispatch(handleLogout())}>
          <Power size={14} className="me-75" />
          <span className="align-middle">Đăng xuất</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
