// ** React Imports
import React, { useState } from 'react'

// ** Third Party Components
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Bell } from 'react-feather'
import { useNavigate } from 'react-router-dom'

// ** Reactstrap Imports
import {
  Button,
  Badge,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown, Row, Col, Card, CardBody,
} from 'reactstrap'

//import component
import CardNotification from '@services/home/notification/pages/CardNotification'
import UnreadNotification from '@services/home/notification/pages/UnreadNotification'

//import hook
import SocialNetworkMutation from '@services/home/hook/mutation'
import KEYS from '@services/home/hook/key'
import SocialNetworkQuery from '../../../../@services/home/hook/query'

const NotificationDropdown = () => {
  const navigate = useNavigate()
  const [rSelected, setRSelected] = useState(1)
  //**State */
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const {
    data: items,
  } = SocialNetworkQuery.useGetAllNotification(KEYS.UNREAD_LIST_NOTIFICATION, {
    is_read: false,
  })
  const { mutate: read_notification } = SocialNetworkMutation.useReadNotification()
  const handleClickRead = () => {
    let dataSubmit = {
      notif_ids: [''],
      clicked_all: false,
      read_all: true,
    }
    read_notification(dataSubmit, KEYS.LIST_NOTIFICATION)
  }

  //**Action*/
  const toggle = () => {
    setdropdownOpen(!dropdownOpen)
  }

  // ** Function to render Notifications
  const renderNotificationItems = () => {
    return (
      <PerfectScrollbar
        component="li"
        className="media-list scrollable-container"
        options={{
          wheelPropagation: false,
        }}
      >
        <CardNotification toggleDropdown={toggle} />
      </PerfectScrollbar>
    )
  }

  // ** Function to render Notifications
  const renderUnreadNotificationItems = () => {
    return (
      <PerfectScrollbar
        component="li"
        className="media-list scrollable-container"
        options={{
          wheelPropagation: false,
        }}
      >
        <UnreadNotification toggleDropdown={toggle} />
      </PerfectScrollbar>
    )
  }

  return (
    <UncontrolledDropdown
      isOpen={dropdownOpen} toggle={toggle}
      tag="li"
      className="dropdown-notification nav-item me-25"
    >
      <DropdownToggle
        tag="a"
        className="nav-link"
        onClick={toggle}
        aria-expanded={dropdownOpen}
      >
        <Bell size={21}/>
        {
          items?.pages && items?.pages[0].metadata?.count > 0 && (
            <Badge pill color="danger" className="badge-up">
              {items?.pages[0].metadata?.count}
            </Badge>
          )
        }
      </DropdownToggle>
      <DropdownMenu end tag="ul" className="dropdown-menu-media mt-0">
        <Row className="m-0 p-0">
          <Col lg={12} sm={12} className="m-auto">
            <Card className={`box-shadow-0 mb-0`}>
              <CardBody className={`pb-0 px-0`}>
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="fw-bolder">Thông báo</h3>
                </div>
                <Row className="mb-1">
                  <Col lg={12}>
                    <div className="">
                      <Button.Ripple className="me-50 bg-light-primary"
                                     color={`${rSelected === 1 ? 'primary' : ''}`} onClick={() => setRSelected(1)}>
                        Tất cả
                      </Button.Ripple>
                      <Button.Ripple className="me-50 bg-light-primary"
                                     color={`${rSelected === 2 ? 'primary' : ''}`} onClick={() => setRSelected(2)}>
                        Chưa đọc
                      </Button.Ripple>
                    </div>
                  </Col>
                  <Col lg={12} className="d-flex align-items-end justify-content-end">
                    <div
                      className="text-end text-primary cursor-pointer"
                      onClick={() => {
                        toggle()
                        navigate(`/notification`)
                      }}
                    >
                      Xem tất cả
                    </div>
                  </Col>
                </Row>
                {rSelected === 1 ? renderNotificationItems() : renderUnreadNotificationItems()}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <li className="dropdown-menu-footer">
          <Button color="primary" block onClick={() => handleClickRead()}>
            Đánh dấu tất cả đã đọc
          </Button>
        </li>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default NotificationDropdown
