import FormValidate from '@core/components/form-validate'
import {PostsMutation} from '@services/home/news-feed/hook'
import {useEffect} from 'react'
import {Button} from 'reactstrap'
import {uploadMedia} from 'utility/Utils'
import schemas from './schema'
const KycForm = ({onNext}) => {
  const {mutate: createKyc, isSuccess} = PostsMutation.useKyc()

  const handleSubmit = async data => {
    let img_front = null
    let img_back = null

    if (data.front_idcard[0]) {
      img_front = await uploadMedia(data.front_idcard[0])
    }
    if (data.backside_idcard[0]) {
      img_back = await uploadMedia(data.backside_idcard[0])
    }

    let dataSubmit = {
      full_name: data.name,
      id_card_number: data.id_card,
      email: data.email,
      id_card_img_front: img_front,
      id_card_img_back: img_back,
      phone_number: data.phone_number,
    }
    // console.log('dataSubmit', dataSubmit)
    createKyc(dataSubmit)
  }

  useEffect(() => {
    if (isSuccess) {
      onNext()
    }
  }, [isSuccess])

  return (
    <>
      <div className="my-custom-form">
        <FormValidate
          data={schemas}
          onSubmit={async data => handleSubmit(data)}
          noBoxShadow
          SubmitComponent={
            <Button
              className="w-100 mt-2"
              color="gradient-primary"
              type="submit"
            >
              <p className="fz-md fw-bolder mb-0">Gửi</p>
            </Button>
          }
        />
      </div>
    </>
  )
}

export default KycForm
