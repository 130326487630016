// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
// import todo from 'views/apps/todo/store'
// import chat from 'views/apps/chat/store'
// import users from 'views/apps/user/store'
// import email from 'views/apps/email/store'
// import invoice from 'views/apps/invoice/store'
// import calendar from 'views/apps/calendar/store'
// import ecommerce from 'views/apps/ecommerce/store'
// import dataTables from 'views/tables/data-tables/store'
// import permissions from 'views/apps/roles-permissions/store'

// ** post
import postReducer from './newsfeed/postReducer'

const rootReducer = {
  auth,
  // todo,
  // chat,
  // email,
  // users,
  navbar,
  layout,
  // invoice,
  // calendar,
  // ecommerce,
  // dataTables,
  // permissions,

  postReducer,
}

export default rootReducer
