// ** Third Party Components
import * as yup from 'yup'
import {useForm, Controller} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'

// ** Reactstrap Imports
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Form,
  Row,
  Col,
} from 'reactstrap'
import {
  CheckField,
  DateTimeField,
  EditorField,
  FileUploadField,
  InputField,
  InputMaskField,
  NumberInputField,
  SelectField,
  SwitchField,
} from '../form-field'

import '@core/scss/react/pages/page-form-validation.scss'
import {ContentState, convertToRaw, EditorState} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import CheckGroupField from '../form-field/CheckGroupField'
import {useCallback, useEffect, useState} from 'react'

const renderValidateSchema = data => {
  let schemas = {}
  if (data.length > 0) {
    schemas = data.reduce((previousValue, currentValue) => {
      if (currentValue.required) {
        return {...previousValue, [currentValue.name]: currentValue.validate}
      }
      return previousValue
    }, {})
  }
  return schemas
}

const renderDefaultValues = data => {
  let defaultValues = {}

  if (data.length > 0) {
    return data.reduce(
      (defaultValue, currentValue) => ({
        ...defaultValue,
        [currentValue.name]:
          currentValue.field === 'editor'
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(currentValue.value).contentBlocks,
                ),
              )
            : currentValue.value,
      }),
      {},
    )
  }

  return defaultValues
}

const FormValidate = ({
  title,
  data = [],
  onSubmit: onSubmitForm,
  validationCustom = [],
  submitText = 'Lưu',
  resetText = 'Làm mới',
  SubmitComponent,
  noBoxShadow = false,
  onWatch = () => {},
}) => {
  const [formData, setFormData] = useState([])
  const [validation, setValidation] = useState({})
  const FormSchema = yup.object().shape(validation)

  let defaultValues = renderDefaultValues(data)

  useEffect(() => {
    if (data.length > 0) {
      setFormData(data)
    }
  }, [data])

  useEffect(() => {
    if (formData.length > 0) {
      setValidation(renderValidateSchema(formData))
    }
  }, [formData])

  // ** Hooks
  const {
    reset,
    control,
    watch,
    handleSubmit,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(FormSchema),
    defaultValues,
  })

  useEffect(() => {
    reset(defaultValues)
  }, [data])

  const onSubmit = values => {
    let editor = data.find(d => d.field === 'editor')
    if (editor && values[editor.name]) {
      values[editor.name] = draftToHtml(
        convertToRaw(values[editor.name].getCurrentContent()),
      )
    }
    onSubmitForm(values)
  }

  const handleReset = () => {
    reset(defaultValues)
  }

  const renderFormContent = useCallback(() => {
    return formData.map(d => {
      if (d.field === 'divider') {
        return (
          <div className="divider">
            <div className="divider-text text-primary">{d.text}</div>
          </div>
        )
      }
      let Field = InputField

      switch (d.field) {
        case 'select':
          Field = SelectField
          break
        case 'date-time':
          Field = DateTimeField
          break
        case 'input-mask':
          Field = InputMaskField
          break
        case 'editor':
          Field = EditorField
          break
        case 'file':
          Field = FileUploadField
          break
        case 'check':
          Field = CheckField
          break
        case 'switch':
          Field = SwitchField
          break
        case 'number-input':
          Field = NumberInputField
          break
        case 'check-group':
          Field = CheckGroupField
          break
        default:
          break
      }

      const Component = (
        <Col md={d.grid} sm="12" className="my-1" key={d.name}>
          <Controller
            id={d.name}
            name={d.name}
            control={control}
            render={({field}) => (
              <Field
                {...d}
                invalid={errors[d.name] && true}
                feedback={errors[d.name] && errors[d.name].message}
                {...field}
              />
            )}
          />
          {d.required &&
            ['file', 'check-group'].includes(d.field) &&
            errors[d.name] &&
            true && (
              <div className="mt-25 text-danger"> {errors[d.name].message}</div>
            )}
        </Col>
      )

      return Component
    })
  }, [formData, control, errors])

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      let comps = [...data]

      if (validationCustom.length > 0) {
        validationCustom.forEach(validation => {
          if (validation.field === name) {
            if (value[name]) {
              let isShow = validation.show(value[name])

              if (validation.type === 'toggle') {
                if (!isShow) {
                  comps = data.filter(c => !validation.results.includes(c.name))
                } else {
                  comps = [...data]
                }
              }
            }
            setFormData(comps)
          }
        })
      }

      onWatch(value, {name, type})
    })
    return () => subscription.unsubscribe()
  }, [data, validationCustom, watch])

  return (
    <Card style={{boxShadow: noBoxShadow ? 'unset' : 'transparent'}}>
      {title && (
        <CardHeader>
          <CardTitle tag="h4">{title}</CardTitle>
        </CardHeader>
      )}
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>{formData.length > 0 && renderFormContent()}</Row>

          {!SubmitComponent ? (
            <div className="d-flex mt-1 justify-content-end">
              <Button className="me-1" color="primary" type="submit">
                {submitText}
              </Button>
              <Button
                outline
                color="secondary"
                type="reset"
                onClick={handleReset}
              >
                {resetText}
              </Button>
            </div>
          ) : (
            SubmitComponent
          )}
        </Form>
      </CardBody>
    </Card>
  )
}

export default FormValidate
