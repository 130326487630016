import axios from 'axios'
import { handleAxios } from 'utility/Utils'

const GroupApi = {
  //**CRUD */
  create_group: data => handleAxios('/create_group', 'post', data),
  delete_group: data => handleAxios('/delete_group', 'delete', data),
  update_group: data => handleAxios('/update_group', 'post', data),

  //**Discover group */
  get_list_discover_group: data =>
    handleAxios(
      `/all_group_discover/${data.user_id}`,
      'get',
      null,
      {},
      data.params,
    ),

  //**Joined Group */
  get_list_joined_group: data =>
    axios.get(`/all_group_joined/${data.uid}`, { params: data.params }).then(res => res.data),

  //**Request to join group */
  get_list_request_to_join: data =>
    handleAxios(
      `/user_list_request_join_group/${data.uid}`,
      'get',
      {},
      {},
      data.params,
    ),

  //** Group manage */
  get_list_all_group_manage: params =>
    axios.get('/list_all_group_manage', { params }).then(res => res.data),

  //**Detail Group */
  get_list_detail_group: id => handleAxios(`/group_info/${id}`, 'get'),

  //**ListMember by Business lv */
  get_list_member: id =>
    handleAxios(`/get-list-profile-business-level?user_id=${id}`, 'get'),

  //**ListMember Group */
  get_list_member_group: data =>
    handleAxios(`/get-list-members`, 'get', null, {}, data),

  //**ListFriend invite Group */
  get_list_invite_to_group: data =>
    handleAxios(`/list-member-invite`, 'get', null, {}, data),

  //**Invite friend to group */
  invite_friend_to_group: data => {
    return handleAxios(
      `/invite_members/group/${data.group_id}`,
      'post',
      data,
      {},
      {},
    )
  },

  //**Role */
  get_list_role_group: () => handleAxios(`/list-role-group`, 'get'),

  //**Follow */
  profile_create_follow: data =>
    handleAxios(`/profile/profile_create_follow`, 'post', data, {}, {}),

  profile_unfollow: data =>
    handleAxios(`/profile/profile_unfollow`, 'post', data, {}, {}),

  //**Remove member */
  remove_member_group: data =>
    handleAxios(`/remove_members`, 'delete', data, {}, {}),

  // ** list post group
  get_list_post_group: params =>
    axios.get('/news-feed', { params }).then(res => res.data),

  //**send request join group */
  send_request_joind_group: data =>
    handleAxios(`/request_join_group`, 'post', data, {}, {}),

  // ** admin accept join group
  accept_request_joind_group: data =>
    axios.post('/accept_request_join_group', data).then(res => res.data),
  // ** admin reject join group
  reject_request_joind_group: data =>
    axios.post('/reject_request_join_group', data).then(res => res.data),

  // ** request join group
  get_list_request_join_group: params => {
    const group_id = params.group_id
    delete params.group_id
    return axios.get(`/group_list_request_join_group/${group_id}`, { params }).then(res => res.data)
  },

  get_list_privileges_group: group_id => {
    return axios.get('/auth-user-group-privileges', { params: group_id }).then(res => res.data)
  },
  //**Cancel request join group */
  cancel_request_join_group: data =>
    handleAxios(`/user_cancel_request_join_group`, 'post', data),

  //**List invitation */
  get_list_invitation: data =>
    handleAxios(
      `/user_list_invitation/${data.user_id}`,
      'get',
      {},
      {},
      data.params,
    ),

  //**User accept invitation to join group */
  user_accept_invitation_to_join: data =>
    handleAxios(`/accept_invitation`, 'post', data),

  //**User reject invitation to join group*/
  user_delete_invitation_to_join: data =>
    handleAxios(`/user_reject_invitation`, 'post', data),

  get_list_group_post_pending: params =>
    axios.get('/list_group_post_pending', {
      params,
    }).then(res => res.data),

  accept_post_pending: data =>
    axios.post('/accept_group_post', data).then(res => res.data),
  deny_post_pending: data =>
    axios.post('/deny_group_post', data).then(res => res.data),

  tranfer_role_member: data =>
    axios.post('/tranfer_group_role', data).then(res => res.data),
  update_group_status: data =>
    axios.post('/update_group_status', data).then(res => res.data),

  get_list_invitation_role_group: params =>
    axios.get('/group_list_role_invitation_sent', {
      params,
    }).then(res => res.data),
  get_user_invitation_role_group: params =>
    axios.get('/user_role_invitation_received', { params }).then(res => res.data),

  deny_role_invition_group: data =>
    axios.post('/deny_role_invitation', data).then(res => res.data),
  accept_role_invition_group: data =>
    axios.post('/accept_role_invitation', data).then(res => res.data),

  //api leave group
  leave_group: data => axios.post('/leave_group', data).then(res => res.data),
}

export default GroupApi
