import kyc from 'assets/images/kyc/kyc-success-form.png'
import {Button} from 'reactstrap'
const SuccessForm = ({successForm}) => {
  return (
    <>
      <p className="text-center fw-bolder fz-xxl mb-2 text-dark">
        Xác minh danh tính của bạn đã hoàn thành
      </p>
      <p className="text-center fz-sm">
        Thông tin xác minh danh tính của bạn đã được ghi nhận. <br />
        Thời gian xét duyệt từ 2 đến 4 giờ. sẽ xác thực tài khoản và gửi thông
        báo đến bạn.
      </p>
      <div className="text-center">
        <img src={kyc} alt="kyc-img" />
      </div>
      <Button.Ripple
        className="w-100 mt-2"
        color="primary"
        onClick={successForm}
      >
        <p className="fz-md fw-bolder mb-0">Đóng</p>
      </Button.Ripple>
    </>
  )
}

export default SuccessForm
