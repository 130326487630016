// ** Navigation imports
// import apps from './apps'
// import pages from './pages'
// import forms from './forms'
// import tables from './tables'
// import others from './others'
// import charts from './charts'
// import dashboards from './dashboards'
// import uiElements from './ui-elements'
import {Circle, Grid, HardDrive} from 'react-feather'
import LandingPage from 'assets/images/svg/ldp.svg'

const training = [
  {
    header: 'Đào tạo',
  },
  {
    id: 'startup',
    title: 'Khởi nghiệp',
    icon: <i className="fa-solid fa-rocket me-1 "></i>,
    navLink: '/training/startup',
    resource: 'STARTUP_TRAINING',
  },
  {
    id: 'skill',
    title: 'Đào tạo kĩ năng',
    icon: <i className="fa-solid fa-briefcase me-1"></i>,
    navLink: '/training/skill',
    resource: 'STARTUP_TRAINING',
  },
]

const social_network = [
  {
    header: 'Mạng cộng đồng',
    resource: 'SOCIAL_NETWORK',
  },
  {
    id: 'user-newsfeed',
    title: 'Bảng tin',
    icon: <i className="fa-solid fa-tv me-1"></i>,
    navLink: '/home',
    resource: 'SOCIAL_NETWORK_NEWSFEED',
  },
  {
    id: 'user-groups',
    title: 'Điểm bán',
    icon: <i className="fa-solid fa-people-group me-1"></i>,
    navLink: '/groups',
    resource: 'SOCIAL_NETWORK_GROUP',
  },
  {
    id: 'user-events',
    title: 'Sự kiện',
    icon: <i className="fa-solid fa-calendar me-1"></i>,
    navLink: '/events',
    resource: 'SOCIAL_NETWORK_EVENT',
  },
  {
    id: 'user-notifications',
    title: 'Thông báo',
    icon: <i className="fa-solid fa-bell me-1" />,
    navLink: '/notification',
    resource: 'SOCIAL_NETWORK_NOTI',
  },
]

const data_center = [
  {
    header: 'DATA CENTER',
    resource: 'DATA_CENTER',
  },
  {
    id: 'd-bizxu',
    title: 'Bizxu',
    icon: <i className="fa-solid fa-coins me-1"></i>,
    navLink: '/data-center/bizxu',
    resource: 'DATA_CENTER_BIZXU',
  },
  {
    id: 'request-access',
    title: 'Yêu cầu truy cập',
    icon: <i className="fa-solid fa-user-plus me-1"></i>,
    navLink: '/request-access',
    resource: 'SOCIAL_NETWORK_ACCESS_REQUEST',
  },
  {
    id: 'buy-data',
    title: 'Mua dữ liệu',
    icon: <i className="fa-solid fa-cloud-arrow-down me-1"></i>,
    navLink: '/data-center/buy-data',
    resource: 'DATA_CENTER_BIZXU',
  },
  {
    id: 'customer-ex',
    title: 'Trải nghiệm KH',
    icon: <i className="fa-solid fa-users-line me-1"></i>,
    navLink: '/data-center/customer-experience',
    resource: '',
  },
  {
    id: 'landing-page',
    title: 'Landing Page',
    icon: <img src={LandingPage} alt="Landing Page" className="me-1" />,
    navLink: '/data-center/landing-page',
    resource: 'DATA_CENTER_LANDING_PAGE',
  },
  {
    id: 'my-data',
    title: 'Dữ liệu của bạn',
    icon: <HardDrive className="me-1" />,
    navLink: '/data-center/my-data',
    resource: 'DATA_CENTER_CUSTOMER_DATA',
  },
]

const admin = [
  {
    header: 'Quản trị hệ thống',
    resource: 'ADMIN',
  },
  // {
  //   id: 'admin-dashboard',
  //   title: 'Dashboard',
  //   icon: <i className="fa-solid fa-house me-1"></i>,
  //   navLink: '/admin/dashboard',
  //   resource: 'ADMIN',
  // },

  {
    id: 'admin-data-center',
    title: 'Data Center',
    icon: <i className="fa-solid fa-database me-1"></i>,
    resource: 'ADMIN_DATA_CENTER',
    children: [
      {
        id: 'data-center-child',
        title: 'Data Center',
        icon: <Circle />,
        navLink: '/admin/data-center/main',
        resource: 'ADMIN_DATA_CENTER_ADMIN_CUSTOMER_DATA',
      },
      {
        id: 'admin-request-access',
        title: 'Yêu cầu truy cập',
        icon: <Circle />,
        navLink: '/admin/community/request-access',
        resource: 'ADMIN_SOCIAL_NETWORK_ACCESS_REQUEST',
      },
      {
        id: 'project-child',
        title: 'Dự án',
        icon: <Circle />,
        navLink: '/admin/data-center/project',
        resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU',
      },
      {
        id: 'admin-bizxu',
        title: 'BizXu',
        icon: <Circle />,
        navLink: '/admin/data-center/bizxu',
        resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU',
      },
      {
        id: 'landing-page-child',
        title: 'Landing Page',
        icon: <Circle />,
        navLink: '/admin/data-center/landing-page',
        resource: 'ADMIN_DATA_CENTER_ADMIN_LANDING_PAGE',
      },
      {
        id: 'config-data',
        title: 'Cấu hình data',
        icon: <Circle />,
        navLink: '/admin/data-center/config-data',
        resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU',
      },
      {
        id: 'admin-source-management',
        title: 'Nguồn dữ liệu',
        icon: <Circle />,
        navLink: '/admin/data-center/source-management',
        resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU',
      },
      // {
      //   id: 'admin-data_manually',
      //   title: 'Thêm data thủ công',
      //   icon: <Circle />,
      //   navLink: '/admin/data-center/data-manually',
      //   resource: 'ADMIN_DATA_CENTER_ADMIN_BIZXU',
      // },
    ],
  },
  // ------------ ** Customer ** ---------------------
  {
    id: 'customer-admin',
    title: 'Khách hàng',
    icon: <i className="fa-solid fa-layer-group"></i>,
    resource: 'BIZXU',
    children: [
      {
        id: 'customer',
        title: 'Khách hàng',
        icon: <Circle />,
        navLink: '/admin/customer/main',
        resource: 'BIZXU',
      },
      {
        id: 'exploit-potential-customer',
        title: 'Khai thác KHTN',
        icon: <Circle />,
        navLink: '/admin/customer/exploit-customer-leads',
        resource: 'BIZXU',
      },
      {
        id: 'potential-customer',
        title: 'KHTN',
        icon: <Circle />,
        navLink: '/admin/customer/customer-leads',
        resource: 'BIZXU',
      },
    ],
  },
  // ------------ ** COMMUNITY ** --------------------
  {
    id: 'community',
    title: 'Cộng đồng',
    icon: <i className="fa-solid fa-building"></i>,
    resource: 'BIZXU',
    children: [
      {
        id: 'member',
        title: 'Thành viên',
        icon: <Circle />,
        navLink: '/admin/community/member',
        resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY',
      },
      {
        id: 'course',
        title: 'Khóa học',
        icon: <Circle />,
        navLink: '/admin/community/course',
        resource: 'BIZXU',
      },
      {
        id: 'manage-community',
        title: 'Quản lý cộng đồng',
        icon: <Circle />,
        navLink: '/admin/community/manage',
        resource: 'BIZXU',
      },
      {
        id: 'post-community',
        title: 'Bài viết',
        icon: <Circle />,
        navLink: '/admin/community/post',
        resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY',
      },
      {
        id: 'group-community',
        title: 'Điểm bán',
        icon: <Circle />,
        navLink: '/admin/community/group',
        resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY',
      },
      {
        id: 'event-community',
        title: 'Sự kiện',
        icon: <Circle />,
        navLink: '/admin/community/event',
        resource: 'ADMIN_SOCIAL_NETWORK_COMMUNITY',
      },
    ],
  },
  // ------------ ** OTHER FUNCTION ** ---------------
  {
    id: 'other-fn',
    title: 'Chức năng khác',
    icon: <Grid />,
    resource: 'BIZXU',
    children: [
      {
        id: 'setting-noti',
        title: 'Cài đặt thông báo',
        icon: <Circle />,
        navLink: '/admin/setting/notification',
        resource: 'BIZXU',
      },
      {
        id: 'config-data',
        title: 'Cấu hình data',
        icon: <Circle />,
        navLink: '/admin/setting/config-data',
        resource: 'BIZXU',
      },
      {
        id: 'setting-account',
        title: 'Chức năng tài khoản',
        icon: <Circle />,
        navLink: '/admin/setting/account',
        resource: 'BIZXU',
      },
      {
        id: 'setting-manage',
        title: 'Chức năng quản trị',
        icon: <Circle />,
        navLink: '/admin/setting/manage',
        resource: 'BIZXU',
      },
    ],
  },
  // ------------ ** ROLE PERMISSION ** --------------
  {
    id: 'admin-role-permisstion',
    title: 'Phân quyền',
    icon: <i className="fa-solid fa-scale-balanced me-1"></i>,
    resource: 'ADMIN_ROLE_PERMISSTION',
    children: [
      {
        id: 'role-list',
        title: 'Vai trò',
        icon: <Circle />,
        navLink: '/admin/role-permission/roles',
        resource: 'ADMIN_ROLE_PERMISSTION_ROLE',
      },
      {
        id: 'role-user',
        title: 'Người dùng - Vai trò',
        icon: <Circle />,
        navLink: '/admin/role-permission/users',
        resource: 'ADMIN_ROLE_PERMISSTION_USER_ROLE',
      },

      {
        id: 'role-list-group',
        title: 'Vai trò - Điểm bán',
        icon: <Circle />,
        navLink: '/admin/role-permission/groups',
        resource: 'ADMIN_ROLE_PERMISSTION_ROLE',
      },
      // {
      //   id: 'role-permisstion',
      //   title: 'Danh sách quyền',
      //   icon: <Circle />,
      //   navLink: '/admin/role-permission/permissions',
      // },
    ],
  },

  // {
  //   id: 'admin-system',
  //   title: 'Hệ thống',
  //   icon: <i className="fa-solid fa-scale-balanced me-1"></i>,
  //   resource: 'ADMIN_DATA_CENTER',
  //   children: [
  //     {
  //       id: 'role-list',
  //       title: 'Tặng quà',
  //       icon: <Circle />,
  //       navLink: '/admin/role-permission/roles',
  //       resource: 'ADMIN_ROLE_PERMISSTION_ROLE',
  //     },
  //     {
  //       id: 'role-user',
  //       title: 'Người dùng - Vai trò',
  //       icon: <Circle />,
  //       navLink: '/admin/role-permission/users',
  //       resource: 'ADMIN_ROLE_PERMISSTION_USER_ROLE',
  //     },
  //   ],
  // },
  // ------------ ** SYSTEM ** -----------------------
  {
    id: 'admin-system',
    title: 'Hệ thống',
    icon: <Grid />,
    resource: 'ADMIN_DATA_CENTER',
    children: [
      {
        id: 'label',
        title: 'Danh hiệu',
        icon: <Circle />,
        navLink: '/admin/system/label',
        resource: 'ADMIN_DATA_CENTER_BIZXU',
      },
      {
        id: 'activity-level',
        title: 'Cấp hoạt động',
        icon: <Circle />,
        navLink: '/admin/system/activity_level',
        resource: 'ADMIN_DATA_CENTER_BIZXU',
      },
      {
        id: 'gift-bizxu',
        title: 'Tặng quà',
        icon: <Circle />,
        navLink: '/admin/system/gift',
        resource: 'ADMIN_DATA_CENTER_BIZXU',
      },
      {
        id: 'violation-policy',
        title: 'Chính sách vi phạm',
        icon: <Circle />,
        navLink: '/admin/system/violate',
        resource: 'ADMIN_DATA_CENTER_BIZXU',
      },
    ],
  },
]

// ** Merge & Export
const configs = [
  ...social_network,
  ...data_center,
  ...training,
  ...admin,

  //   ...apps,
  //   ...pages,
  //   ...uiElements,
  //   ...forms,
  //   ...tables,
  //   ...charts,
  //   ...others,
]

export default configs
