import toast from 'react-hot-toast'
import {useSelector} from 'react-redux'
import {useMutation, useQueryClient} from 'react-query'

import KEYS from './key'
import keys from '@services/home/profile/hook/key'
import PostsApi from './api'

import {getUserData} from 'auth/utils'
import {useCreatePostCtx} from '../context/create-post'

const GET_LIST_POST_GROUP = 'GET_LIST_POST_GROUP'
// ** function define

export const useSetQueryData = () => {
  const queryClient = useQueryClient()
  return {
    update: (data, postId, key) => {
      const dataQuery = queryClient.getQueryData(key)
      if (dataQuery) {
        queryClient.setQueryData(key, oldData => {
          if (oldData) {
            const newPagesArray =
              oldData?.pages.map(page => ({
                ...page,
                data: page.data.map(val =>
                  val.post_id === postId ? data : val,
                ),
              })) ?? []

            return {
              pages: newPagesArray,
              pageParams: oldData.pageParams,
            }
          }
        })
      }
    },
    create: (data, key) => {
      const dataQuery = queryClient.getQueryData(key)
      if (dataQuery) {
        queryClient.setQueryData(key, oldData => {
          if (oldData) {
            const newPagesArray =
              oldData?.pages.map((page, idx) =>
                idx === 0
                  ? {
                      ...page,
                      data: [data, ...page.data],
                    }
                  : page,
              ) ?? []

            return {
              pages: newPagesArray,
              pageParams: oldData.pageParams,
            }
          }
        })
      }
    },
    deletePost: (idPost, key) => {
      const dataQuery = queryClient.getQueryData(key)
      if (dataQuery) {
        queryClient.setQueryData(key, oldData => {
          if (oldData) {
            const newPagesArray =
              oldData?.pages.map(page => ({
                ...page,
                data: page.data.filter(val => val.post_id !== idPost),
              })) ?? []

            let a = []
            newPagesArray.map(it => (a = [...a, ...it.data]))
            if (!a.length) queryClient.invalidateQueries(key)

            return {
              pages: newPagesArray,
              pageParams: oldData.pageParams,
            }
          }
        })
      }
    },
  }
}

const useUpdatePostFunction = () => {
  const queryClient = useQueryClient()
  const {update} = useSetQueryData()
  const {uid} = getUserData()

  const {idProfileViewer} = useSelector(state => state.postReducer)

  return {
    updateFn: (data, type, post_id) => {
      const {post_group_info, post_privacy} = data
      queryClient.invalidateQueries([
        KEYS.GET_POST_DETAIL_BY_POST_ID,
        {
          id: post_id,
          user_id: uid,
        },
      ])

      switch (type) {
        case 'newfeed':
          // cập nhật bài viết trên newfeed
          if (post_privacy?.name === 'Tin nổi bật') {
            queryClient.invalidateQueries(KEYS.GET_LIST_POST_PIN)
          } else update(data, post_id, [KEYS.GET_LIST_ALL_POST, uid])
          break

        case 'newsfeed-group':
          // cập nhật bài viết trên newfeed tab group
          if (post_privacy?.name === 'Tin nổi bật') {
            queryClient.invalidateQueries(KEYS.GET_LIST_POST_PIN)
          } else {
            update(data, post_id, [
              GET_LIST_POST_GROUP,
              post_group_info.group_id,
            ])
            update(data, post_id, [KEYS.GET_LIST_ALL_POST_TAB_GROUP, uid])
          }
          break
        case 'group':
          // cập nhật bài viết trong nhóm
          if (post_group_info)
            update(data, post_id, [
              GET_LIST_POST_GROUP,
              post_group_info.group_id,
            ])
          break
        case 'profile':
          // tạo bài viết trong nhóm
          update(data, post_id, [
            'GET_LIST_ALL_POST_PROFILE',
            idProfileViewer,
          ])

          break

        default:
          break
      }
    },
  }
}

export const useUpdateInfoGroup = () => {
  const queryClient = useQueryClient()
  return {
    updateInfoGroup: (groupId, option) => {
      queryClient.setQueryData(['GET_GROUP_DETAIL', groupId], oldData => {
        if (oldData) {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              total_post_pending:
                option === 'increase'
                  ? oldData.data.total_post_pending + 1
                  : oldData.data.total_post_pending - 1,
            },
          }
        }
      })
    },
  }
}
// **  end function define

const useCreateProfileReference = cb => {
  const queryClient = useQueryClient()
  return useMutation(PostsApi.create_profile_reference, {
    onSuccess: res => {
      queryClient.invalidateQueries(KEYS.LIST_REQUEST_ACCESS)
      cb()
    },
  })
}

const useCreatePost = (type = '') => {
  const queryClient = useQueryClient()
  const {create} = useSetQueryData()
  const {updateInfoGroup} = useUpdateInfoGroup()
  const {setGroupShare} = useCreatePostCtx()
  const {idProfileViewer} = useSelector(state => state.postReducer)

  return useMutation(PostsApi.create_post, {
    onSuccess: async res => {
      const {uid} = getUserData()
      const data = await PostsApi.get_post_detail({
        id: res.data.id,
        user_id: uid,
      })

      if (data) {
        const {post_group_info, post_privacy} = data.data
        let approve = !res.data.is_create_post_without_approve

        switch (type) {
          case 'newfeed':
            // tạo bài viết trên newfeed
            if (post_privacy?.name === 'Tin nổi bật') {
              queryClient.invalidateQueries(KEYS.GET_LIST_POST_PIN)
            } else {
              if (post_group_info) {
                // create(data.data, [KEYS.GET_LIST_ALL_POST_TAB_GROUP, uid])
                // create(data.data, [
                //   GET_LIST_POST_GROUP,
                //   post_group_info.group_id,
                // ])
                create(data.data, [
                  'GET_LIST_GROUP_POST_PENDING',
                  post_group_info.group_id,
                ])
                toast.success(
                  res.message
                    ? res.message
                    : 'Bài viết của bạn đang chờ duyệt!',
                )
              } else {
                create(data.data, [KEYS.GET_LIST_ALL_POST, uid])
                toast.success(
                  res.message ? res.message : 'Đăng bài viết thành công!',
                )
              }
            }

            break
          case 'newsfeed-group':
            // tạo bài viết trên newfeed tab group
            if (post_privacy?.name === 'Tin nổi bật') {
              queryClient.invalidateQueries(KEYS.GET_LIST_POST_PIN)
            } else {
              if (post_group_info) {
                // create(data.data, [KEYS.GET_LIST_ALL_POST_TAB_GROUP, uid])
                // create(data.data, [
                //   GET_LIST_POST_GROUP,
                //   post_group_info.group_id,
                // ])
                if (approve) {
                  create(data.data, [
                    'GET_LIST_GROUP_POST_PENDING',
                    post_group_info.group_id,
                  ])

                  // cập nhật lại số lượng bài viết chờ
                  updateInfoGroup(post_group_info.group_id, 'increase')

                  toast.success(
                    res.message
                      ? res.message
                      : 'Bài viết của bạn đang chờ duyệt!',
                  )
                } else {
                  // cập nhật lại danh sách bài viết trong nhóm
                  create(data.data, ['GET_LIST_POST_GROUP', post_group_info.group_id])

                  toast.success(
                    res.message ? res.message : 'Đăng bài viết thành công!',
                  )
                }
              } else {
                create(data.data, [KEYS.GET_LIST_ALL_POST, uid])
                toast.success(
                  res.message ? res.message : 'Đăng bài viết thành công!',
                )
              }
            }

            break
          case 'group':
            // tạo bài viết trong nhóm
            if (post_group_info) {
              if (approve) {
                create(data.data, [
                  'GET_LIST_GROUP_POST_PENDING',
                  post_group_info.group_id,
                ])

                // cập nhật lại số lượng bài viết chờ
                updateInfoGroup(post_group_info.group_id, 'increase')

                toast.success(
                  res.message ? res.message : 'Bài viết của bạn đang chờ duyệt!',
                )
              } else {

                // cập nhật lại danh sách bài viết trong nhóm
                create(data.data, ['GET_LIST_POST_GROUP', post_group_info.group_id])

                toast.success(
                  res.message ? res.message : 'Đăng bài viết thành công!',
                )
              }

            } else {
              create(data.data, [KEYS.GET_LIST_ALL_POST, uid])
              toast.success(
                res.message ? res.message : 'Đăng bài viết thành công!',
              )
            }

            break
          case 'profile':
            // tạo bài viết trong nhóm
            create(data.data, ['GET_LIST_ALL_POST_PROFILE', idProfileViewer])
            toast.success(
              res.message ? res.message : 'Đăng bài viết thành công!',
            )
            break

          default:
            break
        }
      }

      setGroupShare(null)
    },
    onError: error => {
      toast.error(error.response.data?.msg || 'Lỗi khi tạo bài viết!')
    },
  })
}

const useEditPost = type => {
  const {updateFn} = useUpdatePostFunction()

  return useMutation(PostsApi.update_post, {
    onSuccess: async res => {
      const {uid} = getUserData()
      const data = await PostsApi.get_post_detail({
        id: res.post_id,
        user_id: uid,
      })

      if (data) {
        updateFn(data.data, type, res.post_id)
        toast.success(
          res.message ? res.message : 'Cập nhật bài viết thành công!',
        )
      }
    },
    onError: error => {
      toast.error(error.response.data?.msg || 'Lỗi khi cập nhật bài viết')
    },
  })
}

const useDeletePost = options => {
  const queryClient = useQueryClient()
  const {deletePost} = useSetQueryData()
  const {uid} = getUserData()

  const {idProfileViewer} = useSelector(state => state.postReducer)

  return useMutation(PostsApi.delete_post, {
    onSuccess: res => {
      if (res) {
        // dispatch(handleDeletePost(res.id))
        switch (options?.type) {
          case 'newfeed':
            // xóa bài viết trên newfeed
            queryClient.invalidateQueries(KEYS.GET_LIST_POST_PIN)
            deletePost(res.id, [KEYS.GET_LIST_ALL_POST, uid])
            break

          case 'newsfeed-group':
            // xóa bài viết trên newfeed tab group
            deletePost(res.id, [KEYS.GET_LIST_ALL_POST_TAB_GROUP, uid])
            break

          case 'group':
            // xóa bài viết trong nhóm
            deletePost(res.id, [GET_LIST_POST_GROUP, options?.groupId])
            break

          case 'profile':
            // xóa bài viết trong nhóm

            deletePost(res.id, ['GET_LIST_ALL_POST_PROFILE', idProfileViewer])
            break

          default:
            break
        }
        toast.success(res.message ? res.message : 'Xoá bài viết thành công')
      }
    },
    onError: error => {
      toast.error(error.response.data?.msg || 'Lỗi khi xoá bài viết')
    },
  })
}

const useAddComment = (id, type) => {
  const queryClient = useQueryClient()
  const {updateFn} = useUpdatePostFunction()
  return useMutation(PostsApi.create_comment, {
    onSuccess: async res => {
      // ** update list cmt or replies
      if (res.data.target_type === '1') {
        queryClient.invalidateQueries([KEYS.GET_LIST_COMMENTS_OF_POST, id])
        queryClient.invalidateQueries(
          'GET_POST_DETAIL_BY_POST_ID',
          res.data.target_id,
        )
        //** update bài post
        let dataDetail = null
        if (res.data.target_id) {
          dataDetail = await PostsApi.get_post_detail({
            id: res.data.target_id,
            user_id: res.data.user_id,
          })
        }
        if (dataDetail) {
          updateFn(dataDetail?.data, type, res.data.target_id)
        }
      }
      if (res.data.target_type === '3') {
        queryClient.invalidateQueries([KEYS.GET_LIST_REPLIES_COMMENT, id])
      }
    },
    onError: error => {
      toast.error(error.response.data?.msg || 'Lỗi khi bình luận')
    },
  })
}

const useEditComment = (target_id, target_type) => {
  const queryClient = useQueryClient()

  return useMutation(PostsApi.edit_comment, {
    onSuccess: () => {
      if (target_type === '1') {
        queryClient.invalidateQueries([
          KEYS.GET_LIST_COMMENTS_OF_POST,
          target_id,
        ])
      }
      if (target_type === '3') {
        queryClient.invalidateQueries([
          KEYS.GET_LIST_REPLIES_COMMENT,
          target_id,
        ])
      }
    },
    onError: error => {
      toast.error(error.response.data?.msg || 'Lỗi khi cập nhật bình luận')
    },
  })
}

const useDeleteComment = (
  target_id,
  target_type,
  target_id_delete_cmt,
  type,
) => {
  const queryClient = useQueryClient()
  const {updateFn} = useUpdatePostFunction()

  return useMutation(PostsApi.delete_comment, {
    onSuccess: async res => {
      if (target_type === '1') {
        queryClient.invalidateQueries([
          KEYS.GET_LIST_COMMENTS_OF_POST,
          target_id,
        ])
        queryClient.invalidateQueries(
          'GET_POST_DETAIL_BY_POST_ID',
          target_id_delete_cmt,
        )
        //** update bài post
        let dataDetail = null
        if (target_id_delete_cmt) {
          dataDetail = await PostsApi.get_post_detail({
            id: target_id_delete_cmt,
            user_id: res.data.user_id,
          })
        }
        if (dataDetail) {
          updateFn(dataDetail?.data, type, target_id_delete_cmt)
        }
      }
      if (target_type === '3') {
        queryClient.invalidateQueries([
          KEYS.GET_LIST_REPLIES_COMMENT,
          target_id,
        ])
      }
    },
    onError: error => {
      toast.error(error.response.data?.msg || 'Lỗi khi xoá bình luận')
    },
  })
}

const useLikePost = type => {
  const queryClient = useQueryClient()
  const {updateFn} = useUpdatePostFunction()

  return useMutation(PostsApi.react_post, {
    onSuccess: async res => {
      let dataDetail = null
      if (res.data.target_id) {
        dataDetail = await PostsApi.get_post_detail({
          id: res.data.target_id,
          user_id: res.data.user_id,
        })
      }
      if (dataDetail) {
        updateFn(dataDetail?.data, type, res.data.target_id)
      }

      queryClient.invalidateQueries(
        'GET_POST_DETAIL_BY_POST_ID',
        res.data.target_id,
      )
    },
    onError: error => {
      toast.error(error.response.data?.msg || 'Lỗi khi reaction')
    },
  })
}

const useReactionComment = (type, idPost) => {
  const queryClient = useQueryClient()
  return useMutation(PostsApi.react_post, {
    onSuccess: res => {
      if (type === 'comment') {
        queryClient.invalidateQueries([KEYS.GET_LIST_COMMENTS_OF_POST, idPost])
        queryClient.invalidateQueries([KEYS.GET_LIST_POST_PIN])
      } else {
        queryClient.invalidateQueries([KEYS.GET_LIST_REPLIES_COMMENT, idPost])
      }
    },
    onError: error => {
      toast.error(error.response.data?.msg || 'Lỗi khi reaction')
    },
  })
}

const usePostBookmark = (is_bookmark) => {
  const queryClient = useQueryClient()
  return useMutation(PostsApi.post_bookmark, {
    onSuccess: res => {
      queryClient.invalidateQueries(keys.GET_POST_BOOKMARK)
      if (!is_bookmark) {
        toast.success(res?.message || res?.msg || 'Lưu bài viết thành công')
      } else {
        toast.success(res?.message || res?.msg || 'Bỏ lưu bài viết thành công')
      }
    },
    onError: () => {
      if (!is_bookmark) {
        toast.error('Lỗi khi lưu bài viết')
      } else {
        toast.error('Lỗi khi bỏ lưu bài viết')
      }
    },
  })
}

const useReport = () => {
  return useMutation(PostsApi.report_service, {
    onError: () => {
      toast.error('Lỗi khi báo cáo bài viết')
    },
  })
}

const useKyc = () => {
  return useMutation(PostsApi.kyc_service, {
    onError: err => {
      toast.error(err.response?.data?.detail[0]?.msg || 'Lỗi khi kyc')
    },
  })
}

const PostMutation = {
  useCreateProfileReference,
  useDeleteComment,
  useAddComment,
  useLikePost,
  useEditComment,
  useCreatePost,
  useDeletePost,
  useEditPost,
  useReactionComment,
  usePostBookmark,
  useReport,
  useKyc,
}
export default PostMutation
