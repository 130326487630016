import { QueryClient } from 'react-query'
import toast from 'react-hot-toast'

// export function queryErrorHandler(error) {
//   if (error.response) {
//     const title = error.response.data
//     if (title && title.detail === 'Token is expired') {
//       localStorage.clear()
//       window.location.reload()
//     } else {
//       return toast.error(
//         title && title.msg
//           ? title.msg
//           : 'Không thể thực hiện yêu cầu, xin thử lại!',
//       )
//     }
//   }
// }

export function queryErrorGetHandler (error) {
  if (error.response) {
    const errorData = error.response.data
    if (
      errorData &&
      (errorData.detail === 'Token is expired' ||
        errorData.detail === 'Token is not valid')
    ) {
      window.location.href = '/login'
      localStorage.clear()
    }

    if (errorData && errorData.error_code) {
      toast.error(errorData.error_code)
    }
  }
}

export const defaultQueryClientOptions = {
  queries: {
    onError: queryErrorGetHandler,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  },
  // mutations: {
  //   onError: queryErrorHandler,
  // },
}

export const queryClient = new QueryClient({
  defaultOptions: defaultQueryClientOptions,
})
