import axios from 'axios'
import {handleAxios} from 'utility/Utils'

const ProfileApis = {
  get_detail_profile: account_id =>
    handleAxios(`/profile/get-detail/${account_id}`, 'get'),
  get_list_select_options: search =>
    handleAxios('/profile/profile', 'get', null, {}, {search}),
  get_list_use_following: params =>
    axios
      .get('/profile/profile-list-following', {params})
      .then(res => res.data),
  get_list_Tags: params =>
    axios
    .get('/profile/profile-list-tags', {params})
    .then(res => res.data),
  kyc_close_welcome: () =>
    axios.post('/kyc-close-welcome').then(res => res.data),
}
export default ProfileApis
