import {CKEditor} from '@ckeditor/ckeditor5-react'
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Editor from '@tanhdmt/ckeditor5-custom-build'

// import Font from '@ckeditor/ckeditor5-font/src/font'
// import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily'

// import FieldLayout from './FieldLayout'
// *** Service upload imgage
import {FormFeedback, Label} from 'reactstrap'

class MyUploadAdapter {
  constructor(loader, module, setUrl) {
    this.loader = loader
    this.setUrl = setUrl
    this.module = module || ''
  }
  upload() {
    return this.loader.file.then(
      file =>
        new Promise(async (resolve, reject) => {
          // const downloadURL = await uploadSingleImageFirebase(file, this.module)
          // if (downloadURL) {
          //   this.setUrl(downloadURL)
          //   resolve({
          //     default: downloadURL,
          //   })
          // }
        }),
    )
  }
}

const EditorField = ({
  name = '',
  data = '',
  module,
  getUrl = () => {},
  onChange = () => {},
  label,
  required,
  invalid = false,
  feedback,
  id,
  ...rest
}) => {
  const renderComponent = () => (
    <>
      <div className={` relative ${invalid ? 'is-invalid' : ''}`}>
        <CKEditor
          editor={Editor}
          data={data}
          config={{
            mediaEmbed: {previewsInData: true},
          }}
          className=""
          onReady={editor => {
            if (
              editor &&
              editor.plugins &&
              editor.plugins.get('FileRepository')
            )
              editor.plugins.get('FileRepository').createUploadAdapter =
                loader => {
                  return new MyUploadAdapter(loader, module, url => getUrl(url))
                }
          }}
          {...rest}
          onChange={(event, editor) => {
            const datas = editor.getData()
            onChange(datas)
          }}
        />
      </div>
      {<FormFeedback>{feedback}</FormFeedback>}
    </>
  )
  if (!name) return null
  return (
    <>
      {label && (
        <Label sm="3" size="lg" className="form-label fw-bolder" for={id}>
          {label}{' '}
          {required && (
            <sup
              style={{
                color: '#FF0000',
                fontSize: '1rem',
                top: '0',
              }}
            >
              *
            </sup>
          )}
        </Label>
      )}
      {renderComponent()}
    </>
  )
}

export default EditorField
