// ** Redux Imports
import {createSlice} from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from 'auth/jwt/useJwt'
import {handleReducePrivileges} from 'utility/Utils'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    privileges: null,
  },
  reducers: {
    handleLogin: (state, {payload}) => {
      state.userData = payload
      state[config.storageTokenKeyName] = payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] =
        payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(payload))
      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify(payload.accessToken),
      )
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify(payload.refreshToken),
      )
    },

    handleLogout: state => {
      state.userData = {}
      state.privileges = null
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage'
      localStorage.clear() //for localStorage
      // localStorage.removeItem('userData')
      // localStorage.removeItem(config.storageTokenKeyName)
      // localStorage.removeItem(config.storageRefreshTokenKeyName)
      window.location.href = '/login'
    },
  },
})

export const {handleLogin, handleLogout} = authSlice.actions

export default authSlice.reducer
