import React from 'react'
import {
  Alert,
  CardText,
  Col,
  Row,
} from 'reactstrap'
import moment from 'moment'
import 'moment/locale/vi'
import {useNavigate} from 'react-router-dom'

//**CSS
import './CardNotification.css'

//import hook
import SocialNetworkQuery from '@services/home/hook/query'
import KEYS from '@services/home/hook/key'

//import component
import CustomLoading from '@core/components/custom-loading/CustomLoading'
import { Fragment } from '@fullcalendar/core'
import SocialNetworkMutation from '../../hook/mutation'

const UnreadNotification = ({ toggleDropdown }) => {
  let navigate = useNavigate()
  const {
    data: items,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = SocialNetworkQuery.useGetAllNotification(KEYS.UNREAD_LIST_NOTIFICATION, {
    is_read: false,
    page: 1,
    limit: 10,
  })
  const { mutate: read_notification } = SocialNetworkMutation.useReadNotification()

  if (
    !items ||
    (items.pages &&
      items.pages[0].data?.length === 0)
  )
    return (
      <Alert color="primary">
        <div className="alert-body">
          <span className="fw-bold">
            Không có dữ liệu
          </span>
        </div>
      </Alert>
    )

  const renderRedirect = item => {
    toggleDropdown()
    const {notif_target_type, notif_target, notif_feature_id} = item
    // console.log('item', item)
    switch (notif_target_type) {
      case 'POST':
        if (notif_feature_id) {
          const ids = notif_feature_id.split('_')
          navigate(`/home/newsfeed/${notif_target}#${ids[ids.length - 1]}`)
        } else navigate(`/home/newsfeed/${notif_target}`)
        break
      case 'PROFILE':
        navigate(`/profile/${item?.notif_owner_info?.uid}/feed`)
        break
      default:
        break
    }
  }

  const handleClickReadNoti = (item) => {
    if (item?.notif_is_read === false) {
      read_notification(
        {notif_ids: [item._id]},
        {
          onSuccess: () => {
            renderRedirect(item)
          },
        },
      )
    } else {
      renderRedirect(item)
    }
  }

  return (
    <Row className="mx-0">
      {
        items?.pages?.map((item) => (
          <Fragment key={`${Math.random()}`}>
            {
              item?.data?.map((it) => (
                <Col key={`${Math.random()}-${it?.notif_content}`} lg="12" className="px-0">
                  <div
                    className="col-notifi p-1 d-flex align-items-center mb-1 cursor-pointer"
                    onClick={() => handleClickReadNoti(it)}
                  >
                    <div className="avatar me-2">
                      <img className=""
                           src={it?.notif_img}
                           alt={it?.notif_content}
                           height="40" width="40"
                           style={{ objectFit: 'cover' }}/>
                      <div className="avatar-notifi-icon">
                        <i className="fa-solid fa-tv align-items-center" style={{ fontSize: '9px' }}/>
                      </div>
                    </div>
                    <div className="my-auto w-100">
                      <Row>
                        <Col lg={10} className="pe-0">
                          <CardText className="font-small-3 mb-0 three-dot-2">
                            {it?.notif_content}
                          </CardText>
                          <CardText className="font-small-3 mb-0 text-secondary">
                            {moment(`${it.notif_created_at}Z`).locale('vi').fromNow()}
                          </CardText>
                        </Col>
                        {
                          !it?.notif_is_read && (
                            <Col lg={2} className="d-flex align-items-center justify-content-end">
                              <span className="notifi-status-unread bg-secondary d-block"/>
                            </Col>
                          )
                        }
                      </Row>
                    </div>
                  </div>
                </Col>
              ))
            }
          </Fragment>
        ))
      }
      {isFetchingNextPage ? (
        <div>
          <CustomLoading />
        </div>
      ) : hasNextPage ? (
        <div className="text-center pb-1">
          <button
            onClick={fetchNextPage}
            type="button"
            className="waves-effect mt-auto py-50 border btn btn-#D7DADE"
            style={{backgroundColor: 'rgb(245, 245, 244)'}}
          >
            Xem thêm
          </button>
        </div>
      ) : (
        ''
      )}
    </Row>
  )
}

export default UnreadNotification
