import KEYS from './key'
import {useQuery} from 'react-query'
import ProfileApis from './api'

const useGetDetailProfile = account_id =>
  useQuery(
    [KEYS.GET_DETAIL_PROFILE, account_id],
    () => ProfileApis.get_detail_profile(account_id),
    {
      enabled: !!account_id,
    },
  )

const useGetUserSelectOptions = search =>
  useQuery([KEYS.GET_USER_SELECT_OPTION, search], () =>
    ProfileApis.get_list_select_options(search),
  )
/**
 * http://54.254.209.224:2000/docs#/Follow/list_follower_profile_list_follower_get
 * @param {*} search
 * @returns
 */
const useGetUserFollowingSelectOptions = params =>
  useQuery([KEYS.GET_USER_FOLLOWING_SELECT_OPTION, params], () =>
    ProfileApis.get_list_use_following(params),
  )

/**
 * https://dev.api.gateway.balpha.hspace.biz/redoc#tag/profile/operation/check_follow_profile_check_follow_get
 * @param {*} search
 * @returns
 */
const useGetListTags = params =>
  useQuery([KEYS.GET_LIST_TAGS, params], () =>
    ProfileApis.get_list_Tags(params),
  )

const ProfileQuery = {
  useGetDetailProfile,
  useGetUserSelectOptions,
  useGetUserFollowingSelectOptions,
  useGetListTags
}

export default ProfileQuery
