import KEYS from './key'
import {useInfiniteQuery, useQuery} from 'react-query'
import PostsApi from './api'
import GroupApi from '@services/home/group/hook/api'
import {serializeAbility} from 'utility/Utils'
import {useAbilityGroupCtx} from 'utility/context/AbilityGroupProvider'

const useGetListAllPost = (params = null) => {
  return useInfiniteQuery(
    [KEYS.GET_LIST_ALL_POST, params.user_id],
    ({pageParam = 1}) =>
      PostsApi.get_list_all_post({...params, page: pageParam}),
    {
      enabled: !!params.user_id,
      getNextPageParam: lastPage => {
        return lastPage.metadata.has_next
          ? lastPage.metadata.current_page + 1
          : undefined
      },
    },
  )
}

const useGetListAllPostProfile = (params = null) => {
  return useInfiniteQuery(
    [KEYS.GET_LIST_ALL_POST_PROFILE, params.uid_view],
    ({pageParam = 1}) =>
      PostsApi.get_list_all_post_profile({...params, page: pageParam}),
    {
      enabled: !!params.uid && !!params.uid_view,
      getNextPageParam: lastPage => {
        return lastPage.metadata.has_next
          ? lastPage.metadata.current_page + 1
          : undefined
      },
    },
  )
}

const useGetListAllPostTabGroup = (params = null) => {
  return useInfiniteQuery(
    [KEYS.GET_LIST_ALL_POST_TAB_GROUP, params.user_id],
    ({pageParam = 1}) =>
      PostsApi.get_list_all_post_tab_group({...params, page: pageParam}),
    {
      enabled: !!params.user_id,
      getNextPageParam: lastPage => {
        if (lastPage) {
          return lastPage.metadata.has_next
            ? lastPage.metadata.current_page + 1
            : undefined
        } else {
          return undefined
        }
      },
    },
  )
}

const useGetListAllGroupPost = (user_id, group_id, page = 1, limit = 5) => {
  return useQuery([KEYS.GET_LIST_POSTS, user_id, group_id, page, limit], () =>
    PostsApi.get_list_posts(user_id, group_id, page, limit),
  )
}

const useGetListCommentsOfPost = (id, data) => {
  return useQuery(
    [KEYS.GET_LIST_COMMENTS_OF_POST, id, data],
    () => PostsApi.get_list_comments_of_post(data),
    {enabled: !!id},
  )
}

const useGetListRepliesComment = (id, data) => {
  return useQuery(
    [KEYS.GET_LIST_REPLIES_COMMENT, id, data],
    () => PostsApi.get_list_replies_comment(data),
    {enabled: !!id},
  )
}

const useGetListPrivacyCreatePost = (params = null) => {
  return useQuery([KEYS.GET_LIST_PRIVACY_CREATE_POST, params], () =>
    PostsApi.get_privacy_post(params),
  )
}

const useGetListReactionOfPost = post_id =>
  useQuery([KEYS.GET_LIST_REACTION_OF_POST, post_id], () =>
    PostsApi.get_list_reaction_of_post(post_id),
  )

const useGetListReactionOfComment = comment_id =>
  useQuery([KEYS.GET_LIST_REACTION_OF_COMMENT, comment_id], () =>
    PostsApi.get_list_reaction_of_comment(comment_id),
  )

/**
 * https://uat.api.bca.hspace.biz/redoc#operation/Get_list_pin_posts_post_pin_get
 * @param {*} user_id
 * @returns
 */
const useGetListPostPin = user_id =>
  useQuery([KEYS.GET_LIST_POST_PIN, user_id], () =>
    PostsApi.get_list_post_pin(user_id),
  )
const useGetListHotArea = user_id =>
  useQuery([KEYS.GET_LIST_HOT_AREA, user_id], () =>
    PostsApi.get_list_hot_area(),
  )

const useGetPostDetail = params =>
  useQuery(
    [KEYS.GET_POST_DETAIL_BY_POST_ID, params],
    () => PostsApi.get_post_detail(params),
    {enabled: !!params.id && !!params.user_id},
  )

// --------------- V2 ---------------
const useGetEmbedLink = data =>
  useQuery([KEYS.GET_EMBED, data], () => PostsApi.get_embed_link(data), {
    enabled: !!data?.url || !!data?.post_id,
  })
/**
 * https://uat.api.bca.hspace.biz/redoc#operation/Get_list_reaction_type_get_list_react_type_get
 * @returns
 */
const useGetListTypeReaction = () =>
  useQuery([KEYS.GET_LIST_TYPE_REACTION_POST], () =>
    PostsApi.get_type_reaction(),
  )

const useGetUserLikePost = params =>
  useQuery(
    [KEYS.GET_LIST_USER_REACT_POST, params],
    () => PostsApi.get_user_reaction(params),
    {enabled: !!params.target_id && !!params.react_name},
  )

const useListGroupFollowing = params => {
  return useInfiniteQuery(
    [KEYS.LIST_GROUP_FOLLOWING, {...params}],
    ({pageParam = 1}) =>
      PostsApi.get_list_group_following({...params, page: pageParam}),
    {
      enabled: !!params.uid,
      getNextPageParam: lastPage => {
        return lastPage.metadata.has_next
          ? lastPage.metadata.current_page + 1
          : undefined
      },
    },
  )
}

const useGetProfileSuggest = () =>
  useQuery(
    [KEYS.GET_LIST_PROFILE_SUGGEST],
    () => PostsApi.get_profile_suggest(),
  )

const useGetGroupSuggest = () =>
  useQuery(
    [KEYS.GET_LIST_GROUP_SUGGEST],
    () => PostsApi.get_group_suggest(),
  )

const PostsQuery = {
  useGetListCommentsOfPost,
  useGetListRepliesComment,
  useGetListPrivacyCreatePost,
  useGetListAllPost,
  useGetListAllGroupPost,
  useGetListReactionOfPost,
  useGetListReactionOfComment,
  useGetListPostPin,
  useGetListHotArea,
  useGetPostDetail,
  useGetEmbedLink,
  useGetListTypeReaction,
  useGetUserLikePost,

  useGetListAllPostTabGroup,
  useGetListAllPostProfile,

  useListGroupFollowing,

  useGetProfileSuggest,
  useGetGroupSuggest
}

export default PostsQuery
