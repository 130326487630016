//**Form */
import * as yup from 'yup'

//**Hook */

const SCHEMAS = [
  {
    field: 'input',
    grid: 6,
    name: 'name',
    placeholder: 'Châu Thu Hằng',
    value: '',
    label: 'Họ và tên',
    required: true,
    validate: yup.string().required('Vui lòng nhập họ và tên'),
  },

  {
    field: 'input',
    type: 'number',
    grid: 6,
    name: 'id_card',
    placeholder: '301XX1234',
    value: '',
    label: 'Số CMND/CCCD',
    required: true,
    validate: yup.string().required('Vui lòng nhập số CMND'),
  },

  {
    field: 'input',
    type: 'email',
    grid: 6,
    name: 'email',
    placeholder: 'abc@gmail.com',
    value: '',
    label: 'Email',
    required: true,
    validate: yup.string().required('Vui lòng nhập email'),
  },

  {
    field: 'input',
    type: 'number',
    grid: 6,
    name: 'phone_number',
    placeholder: '09XX123456',
    value: '',
    label: 'Số điện thoại',
    required: true,
    validate: yup.string().required('Vui lòng nhập số CMND'),
  },

  {
    field: 'file',
    grid: 12,
    name: 'front_idcard',
    label: 'Mặt trước CMND',
    value: [],
    multiple: false,
    required: true,
    minHeight: '200px',
    maxFiles: 1,
    validate: yup.array().required('CMND là bắt buộc'),
  },
  {
    field: 'file',
    grid: 12,
    name: 'backside_idcard',
    label: 'Mặt sau CMND',
    value: [],
    multiple: false,
    required: true,
    minHeight: '200px',
    maxFiles: 1,
    validate: yup.array().required('CMND là bắt buộc'),
  },
]

export default SCHEMAS
