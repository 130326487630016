// ** React Imports
import {Outlet} from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@core/layouts/VerticalLayout'

// ** Menu Items Array
import navigation from 'navigation/vertical'

const VerticalLayout = props => {
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  // navigation = navigation.map(menu => ({...menu, resource: 'TEST'}))

  return (
    <Layout menuData={navigation} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
