import { useInfiniteQuery, useQuery } from 'react-query'
import SocialNetworkApi from './api'

const useGetAllNotification = (key, params) => {
  // return useQuery([key],
  //   () => SocialNetworkApi.get_all_list_notification(params))
  return useInfiniteQuery(
    [key, { ...params }],
    ({ pageParam = 1 }) => SocialNetworkApi.get_all_list_notification({
      ...params,
      page: pageParam,
    }),
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.metadata.has_next ? lastPage.metadata.current_page + 1 : undefined
      },
    },
  )
}

const SocialNetworkQuery = {
  useGetAllNotification,
}

export default SocialNetworkQuery